html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (prefers-reduced-motion: no-preference) {
}

.rcorners {
  border-radius: 25px;
  background-position: left top;
  background-repeat: repeat;

  width: 100%;
  height: 400px;
}

td {
  margin: auto;
  text-align: left;
  position: relative;
  display: block;
  width: 200px;
}

.gridcolumn {
  padding: 1rem;
  background: beige;
}

textarea {
  overflow-y: scroll;
  height: 100px;
  width: 100%;
  resize: none; /* Remove this if you want the user to resize the textarea */
}

@media (min-width: 800px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-container {
  max-width: 1600px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
}

.link {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
