.correct-row,
.correct-row * {
  color: green !important;
}
.incorrect-row,
.incorrect-row * {
  color: orange !important;
}
.red-row,
.red-row * {
  color: red !important;
}
