.leaflet-container {
  border-radius: 25px;
  background-position: left top;
  background-repeat: repeat;

  position: relative;
  height: 300px;
  width: 100%;
  z-index: 0;
}
