.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.column {
  flex: 1;
  max-height: 100%;
  margin: 0 1em;
  display: flex;
  flex-direction: column;
}

.photos {
  flex: 1;
  overflow-y: scroll;
  text-align: center;
}

.photo-preview {
  width: 90%;
}

.download-photo {
  color: white;
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  display: none !important;
}

.photo-link:hover .download-photo {
  display: inline-block !important;
}
